<template>
  <section id="student-assessment" v-if="!loading">
    <v-btn text class="poppins mb-3" color="primary" dense 
      @click="(prevRoute && prevRoute.name === `${user.role.toLowerCase().replace(/^./, c => c.toUpperCase())} Grading Assessment`) ? $router.back() : $router.push({ name: `${user.role.toLowerCase().replace(/^./, c => c.toUpperCase())} Grading Assessment` , params: { id: $route.params.id }, query: { search: '', page: 1, paginate: 10 }})" @displayStudentAssessment="getData()">
      <v-icon small>
        mdi-chevron-left
      </v-icon>
      Back
    </v-btn>
    <HeaderSearch :data="gradingState" @updateStatus="updateStatus" :user_list="gradingUsers" :sum_score="sum_score" :status="checked"/>
    <v-sheet class="custom-border border my-7">
      <Question v-for="(item, i) in gradingState.question_answers" :key="i" :i="i" :data="item" @save="save" :loading="saveLoading"/>
    </v-sheet>
  </section>
  <circular v-else/>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import HeaderSearch from "@/components/teacher/grading/HeaderSearch.vue";
import Question from "@/components/teacher/grading/Question.vue";

export default {
  components: {
    HeaderSearch,
    Question,
  },
  methods: {
    ...mapActions('instructor', ['updateGradingAction', 'getGradingAction', 'getGradingStudent']),

    ...mapActions('instructor', {
      updateInstructorGradingAction: 'updateGradingAction',
      getInstructorGradingAction: 'getGradingAction',
      getInstructorGradingStudent: 'getGradingStudent',
    }),

    ...mapActions('admin', {
      updateAdminGradingAction: 'updateGradingAction',
      getAdminGradingAction: 'getGradingAction',
      getAdminGradingStudent: 'getGradingStudent',
    }),

    ...mapMutations(['alertMutation']),

    updateStatus(e){
      this.grading['status'] = e
    },

    save(){
      
      if(this.user.role === 'INSTRUCTOR') {
        this.saveLoading = true
        this.gradingState.question_answers.forEach(item => {
          this.grading.question_answer_id.push(item.id)
          this.grading.scores.push(item.score ? item.score : 0)
          this.grading.comments.push(item.question_answer_feedback ? item.question_answer_feedback.comment : null)
        })
        this.updateInstructorGradingAction({
          user_assessment_id:  parseInt(this.$route.params.user_assessment_id),
          user_id: this.gradingState.score.user.id,
          assessment_id: this.gradingState.id,
          question_answer_id: this.grading.question_answer_id,
          score: this.grading.scores,
          checked: this.grading.status == null ? this.gradingState.score.checked ? true : false : this.grading.status,
          comment: this.grading.comments
        }).then(()=>{
          this.alertMutation({
            show: true,
            text: `Successfully Updated for ${this.gradingState.score.user.first_name} ${this.gradingState.score.user.last_name}`,
            type: "success"
          })
        }).catch(() => {
          this.alertMutation({
            show: true,
            text: `Something went wrong`,
            type: "error"
          })
        }).finally(() => {
          this.saveLoading = false
        })

      }
      
      if(this.user.role === 'ADMIN') {
        this.saveLoading = true
        this.gradingState.question_answers.forEach(item => {
          this.grading.question_answer_id.push(item.id)
          this.grading.scores.push(item.score ? item.score : 0)
          this.grading.comments.push(item.question_answer_feedback ? item.question_answer_feedback.comment : null)
        })
        this.updateAdminGradingAction({
          user_assessment_id:  parseInt(this.$route.params.user_assessment_id),
          user_id: this.gradingState.score.user.id,
          assessment_id: this.gradingState.id,
          question_answer_id: this.grading.question_answer_id,
          score: this.grading.scores,
          checked: this.grading.status == null ? this.gradingState.score.checked ? true : false : this.grading.status,
          comment: this.grading.comments
        }).then(()=>{
          this.alertMutation({
            show: true,
            text: `Successfully Updated for ${this.gradingState.score.user.first_name} ${this.gradingState.score.user.last_name}`,
            type: "success"
          })
        }).catch(() => {
          this.alertMutation({
            show: true,
            text: `Something went wrong`,
            type: "error"
          })
        }).finally(() => {
          this.saveLoading = false
        })

      }
    },

    getData(){
      if(this.user.role === 'INSTRUCTOR') {
        this.loading = true
        this.getInstructorGradingAction({
          assessment_id: this.$route.params.id,
          user_id: this.$route.params.user_id,
          user_assessment_id: this.$route.params.user_assessment_id
        }).then(res=>{
          this.checked = res.score.checked
          this.sum_score = res.total_points
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }
      if(this.user.role === 'ADMIN') {
        this.loading = true
        this.getAdminGradingAction({
          assessment_id: this.$route.params.id,
          user_id: this.$route.params.user_id,
          user_assessment_id: this.$route.params.user_assessment_id
        }).then(res=>{
          this.checked = res.score.checked
          this.sum_score = res.total_points
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }
    }
  },
  mounted(){
    this.getData()
  },
  computed: {

    ...mapState({
      user: (state) => state.user
    }),

    gradingState(){
      if (!this.user) return [];

      const gradingStateMap = {
          INSTRUCTOR: this.instructorGradingState,
          ADMIN: this.adminGradingState,
      };

      return gradingStateMap[this.user.role] || [];
    },
    
    gradingUsers(){
      if (!this.user) return [];

      const gradingStateMap = {
          INSTRUCTOR: this.instructorGradingUsers,
          ADMIN: this.adminGradingUsers,
      };

      return gradingStateMap[this.user.role] || [];
    },

    ...mapState('instructor', {
      instructorGradingState: (state) => state.grading,
      instructorGradingUsers(state) {
        var user_list = []
        state.gradingUsers.forEach(item => {
          user_list.push({ value: item.user.id, text: `${item.user.first_name ? item.user.first_name : ''} ${item.user.last_name ? item.user.last_name : ''} ${item.user.suffix ? item.user.suffix : ''}`})
        })

        return user_list
      }
    }),

    ...mapState('admin', {
      adminGradingState: (state) => state.grading,
      adminGradingUsers(state) {
        var user_list = []
        state.gradingUsers.forEach(item => {
          user_list.push({ value: item.user.id, text: `${item.user.first_name ? item.user.first_name : ''} ${item.user.last_name ? item.user.last_name : ''} ${item.user.suffix ? item.user.suffix : ''}`})
        })

        return user_list
      }
    })
  },

  data: () => ({
    grading: {
      status: null,
      answers: [],
      question_answer_id: [],
      scores: [],
      comments: []
    },
    saveLoading: false,
    sum_score: 0,
    loading: true,
    checked: 0,
    prevRoute: null,
  }),

  watch: { 
    $route(to, from) { 
      this.getData()
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
        vm.prevRoute = from          
    })
  },
};
</script>

<style></style>
